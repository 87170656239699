import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import NewsArticle  from "../../components/NewsArticle/NewsArticle";
import './NewsDetails.scss';
const NewsDetails = () => {
  return <>
      <Header additionalClass="white news-header" />
     <Footer/>
  </>;
};

export default NewsDetails;

